import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import '../styles/hover-effect.css';

const MembershipPage = () => (
	<Layout title='Become a Member'>
		<h3 className='raleway-700 my-3 text-center'>Become an ASGSR Students Member</h3>
		<p className='px-md-3 my-3 col-md-8 mx-auto'>
			Are you a graduate, undergraduate, high school, or middle school student interested in space science and
			technology? Then, ASGSR is for you! Students under 35 can join ASGSR for just $35. More info{' '}
			<a target='_blank' href='https://asgsr.org/member/'>
				here
			</a>
			.{' '}
		</p>

		<div className='col-lg-8 mx-auto d-flex justify-content-evenly'>
			<iframe
				width='560'
				height='315'
				src='https://www.youtube.com/embed/7JoC4GajWGA'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
		</div>

		<h3 className='raleway-700 my-3 text-center'>Join the Student/Early Career Associates (SECA) Program</h3>
		<p className='px-3 col-md-8 mx-auto'>
			The ASGSR student/early career board is piloting a program to allow students and young professionals to be
			more involved with ASGSR year-round. We invite those interested to apply to be ASGSR Student/Early Career
			Associates (SECAs).
		</p>

		<p className='px-3 col-md-8 mx-auto'>
			Becoming a SECA is a great way to discover the low-gravity research community in the US and the world. SECAs
			help with social media management, conference planning, website development, or the organization of ASGSR
			scholarships. Just let us know what you want to do and we’ll find the best spot for you! SECAs are required
			to…
			<ul className='my-3 mb-5'>
				<li>
					{' '}
					Actively work with an <Link to='/about/team'>ASGSR student board officer</Link> of your choice for
					at least 3 months to support the daily operation of the student board (please reach out to them if
					you have any questions!).
				</li>
				<li>
					{' '}
					Plan (or volunteer at) an outreach event in their own community (classroom visit/virtual classroom
					lesson, Skype a Scientist, tabling at a STEM fair, etc.)
				</li>
				<li> Agree with the diversity and inclusion policy of ASGSR Students</li>
			</ul>
		</p>

		<p className='px-3 col-md-8 mx-auto'>
			Applications can be found{' '}
			<a
				target='_blank'
				href='https://docs.google.com/forms/d/e/1FAIpQLScKi4IcPScFz_1elycqwXK-lHhyJiwrcuZu2mpofVKL98h-Uw/viewform'
			>
				here
			</a>{' '}
			and will be evaluated on a rolling basis. ASGSR membership is not required, but you should certainly
			consider it ;-)
		</p>
	</Layout>
);

export default MembershipPage;
